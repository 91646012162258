<template>
    <div>
        <!-- Filter Contacts By Job Title list -->
        <vx-card class="mt-8">
            <div class="card-title ">
                <h2 class="my-2 w-auto">{{ LabelConstant.headingLabelFilterContactsbyJobTitle }}</h2>  
                <vs-button
                    :title="LabelConstant.buttonLabelAddNewFilter"
                    @click="addNewJobFilterPopup=true"                    
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    class="small-btn bg-actionbutton"
                >
                    {{ LabelConstant.buttonLabelAddNewFilter }}
                </vs-button>          
            </div>
            <div class="common-table">      
                <!--Table Select Row-->
                <div class="table-row-select">
                    <vs-select :label="LabelConstant.inputLabelShowEntries" v-model="perPage" class="show-select" autocomplete>
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recordList" />
                    </vs-select>
                </div>
                <vs-table ref="tableData" :data="contactsFilterKeywords" search :max-items="perPage" pagination>
                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th sort-key="Job_Title">{{ LabelConstant.tableHeaderLabelIncludeTheseJobTitles }}</vs-th>
                        <vs-th>{{ LabelConstant.tabelHeaderLabelMatchType }}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                <div class="table-action">
                                    <vs-button
                                        @click="deleteContactJobTitleFilterPopupActive = true, deletableContactJobTitleFilter = tr.Job_Title"
                                        :title="LabelConstant.buttonLabelDelete"
                                        color="danger"
                                        type="filled"
                                        icon-pack="feather"
                                        icon="icon-trash-2"
                                        class="mr-0"
                                    ></vs-button>
                                </div>
                            </vs-td>
                            <vs-td :data="tr.Job_Title">{{tr.Job_Title}}</vs-td>
                            <vs-td><vs-chip color="warning">{{ LabelConstant.conditionLabelMatchType }}</vs-chip></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{contactsFilterKeywords.length|tableRecordNumberFormatter}} entries</p>
                <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{contactsFilterKeywords.length|tableRecordNumberFormatter}} entries)</p>
                <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>                
            </div> 
        </vx-card>

        <!-- Create New Job Title Filter -->
        <vs-popup :title="LabelConstant.popupTitleCreateNewJobTitleFilter" :active.sync="addNewJobFilterPopup"> 
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-6">{{ LabelConstant.infoLabelCreateAJobTitleFilter }}</p>
                </div>
            </div>
            <template>
                <form data-vv-scope="addNewJobTitleFilterForm">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" :label="LabelConstant.inputLabelJobTitleContains" v-model="jobTitleKeyword" name="jobTitleKeyword" v-validate="'required'"/>
                            <span class="text-danger text-sm">{{ errors.first('addNewJobTitleFilterForm.jobTitleKeyword') }}</span>
                            <p class="ins-text">{{ LabelConstant.infoinputLabelJobTitleContains }}</p>
                        </div>
                    </div>
                </form>
            </template>
            <div class="float-right mt-4 w-full sm:w-auto mb-8">
                <vs-button type="filled" class="w-full sm:w-auto mt-2" color="primary" @click="addContactJobTitleFilter()">{{ LabelConstant.buttonLabelCreateJobTitleFilter }}</vs-button>
                <vs-button @click="cancelAddContactJobTitleFilter()"  color="grey" type="border" class="grey-btn ml-0 sm:ml-4 w-full sm:w-auto mt-2" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
   
        <!-- Delete Contact Job Title Filter? -->
        <vs-popup class="delete-popup" :title="LabelConstant.popupHeadingLabelDeleteContactJobTitleFilter" :active.sync="deleteContactJobTitleFilterPopupActive"> 
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon"><feather-icon icon="Trash2Icon"></feather-icon></div>                    
                        <h4>{{ LabelConstant.popupHeadingLabelDeleteContactJobTitleFilter }}</h4>
                        <span class="deleted-no">{{deletableContactJobTitleFilter}}</span>                    
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">            
                <vs-button type="filled" @click="deleteContactJobTitleFilter()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="cancelDeletedeContactJobTitleFilter()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import { Validator } from 'vee-validate';
const dict = {
    custom: {
        jobTitleKeyword: {
           required: 'A job title is required.',
        },
    }
}
Validator.localize('en', dict);

export default {
    components: {
        VxCard,    
    },
    data() {
        return {
            contactsFilterKeywords: [],

            addNewJobFilterPopup: false,
            jobTitleKeyword: null,

            deleteContactJobTitleFilterPopupActive: false,
            deletableContactJobTitleFilter: null,

            perPage: 5,
            recordList: [
                {text: '5', value: 5},
                {text: '10', value: 10},
                {text: '25', value: 25},
                {text: '50', value: 50},
                {text: '100', value: 100}
            ],       
        };
    },
    created() {
        this.getJobTitleList();
    },
    methods: {
        getJobTitleList(){
            this.$vs.loading();
            this.axios.get("/ws/ConfigFilterContacts/GetJobTitleList").then(response => {
                let data = response.data;
                this.contactsFilterKeywords = data
                this.contactsFilterKeywords.sort(this.GetSortOrder("Job_Title", "ASC")); //Pass the attribute to be sorted on
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        cancelAddContactJobTitleFilter(){
            this.addNewJobFilterPopup = false
            this.jobTitleKeyword = null
            setTimeout(() => { 
                this.errors.clear('addNewJobTitleFilterForm')
            }, 20);
        },
        addContactJobTitleFilter(){
            this.$validator.validateAll('addNewJobTitleFilterForm').then(result => {
                if (result) {
                    let createJobTitleListEntryParams = {
                        Job_Title: this.jobTitleKeyword
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFilterContacts/CreateJobTitleListEntry",createJobTitleListEntryParams).then(() => {
                        this.getJobTitleList()
                        this.cancelAddContactJobTitleFilter()
                        this.$vs.notify({
                            title:'Success',
                            text: 'Job title successfully added to your contact filters.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        cancelDeletedeContactJobTitleFilter(){
            this.deleteContactJobTitleFilterPopupActive = false
            this.deletableContactJobTitleFilter = null
        },
        deleteContactJobTitleFilter(){
            let deleteJobTitleListEntryParams = {
                Job_Title: this.deletableContactJobTitleFilter
            }
            this.$vs.loading()
            this.axios.post("/ws/ConfigFilterContacts/DeleteJobTitleListEntry",deleteJobTitleListEntryParams).then(() => {
                this.$refs.tableData.searchx = null
                this.getJobTitleList()
                this.cancelDeletedeContactJobTitleFilter(),
                this.$vs.notify({
                    title:'Success',
                    text: 'Job title successfully removed from your contact filters.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });
        },
    },
    computed: {
        isTableRecordEntriesDisplay(){
			if(this.contactsFilterKeywords.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
        isSearch: function () {
            if(this.contactsFilterKeywords.length > 0){
                if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
                    return false
                }else{
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if(this.contactsFilterKeywords.length > 0){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
            }
        },
        showingDataTo: function () {
            if(this.contactsFilterKeywords.length > 0){
                let totalPages = this.$refs.tableData.getTotalPages
                if(totalPages == this.$refs.tableData.currentx){
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                }else{
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        },
        totalSearchedData: function () {
            if(this.contactsFilterKeywords.length > 0){
                return this.$refs.tableData.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if(this.contactsFilterKeywords.length > 0){
                let totalPages = this.$refs.tableData.getTotalPagesSearch
                if(totalPages == this.$refs.tableData.currentx){
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                }else{
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        }
    },
};
</script>
